<template>
	<div>
		<div class="title">我的账户</div>
		<div class="balance">
			<div class="left">
				账户余额
				<span>￥ {{ now_money }}</span>
			</div>
			<div class="right">
				<div class="list">
					累计充值（元）
					<span>￥ {{ recharge }}</span>
				</div>
				<div class="list">
					累计消费（元）
					<span>￥ {{ orderStatusSum }}</span>
				</div>
			</div>
		</div>
		<div class="btn-ground">
			<button class="topUp">充值</button>
			<button class="withdraw">提现</button>
		</div>
		<el-tabs v-model="types" @tab-click="tabNav">
			<!-- 充值 -->
			<el-tab-pane label="充值" name="2">
				<div class="item" v-for="(item, index) in billList" :key="index">
					<div class="data">{{ item.time }}</div>
					<template>
						<el-table class="table-box" :data="item.list" style="width: 100%">
							<el-table-column prop="title" label="标题"></el-table-column>
							<el-table-column align="center" prop="number" label="佣金"></el-table-column>
							<el-table-column align="center" prop="add_time" label="添加时间"></el-table-column>
							<el-table-column align="center" label="佣金状态">
								<template slot-scope="scope">
									{{ scope.row.pm == 0 ? '支出' : '收入' }}
								</template>
							</el-table-column>
						</el-table>
					</template>
				</div>
				<el-pagination
					small
					@size-change="SizeChange"
					@current-change="CurrentChange"
					:current-page="queryParam.page"
					:page-size="queryParam.limit"
					:page-sizes="[2, 20, 30, 50]"
					layout="total, sizes, prev, pager, next, jumper"
					total="100"
				></el-pagination>
			</el-tab-pane>
			<!-- 提现 -->
			<el-tab-pane label="提现" name="4">
				<el-table class="table-box" :data="billList.list" style="width: 100%">
					<el-table-column prop="title" label="标题"></el-table-column>
					<el-table-column align="center" prop="number" label="佣金"></el-table-column>
					<el-table-column align="center" prop="add_time" label="添加时间"></el-table-column>
					<el-table-column align="center" label="佣金状态">
						<template slot-scope="scope">
							{{ scope.row.pm == 0 ? '支出' : '收入' }}
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					small
					@size-change="SizeChange"
					@current-change="CurrentChange"
					:current-page="queryParam.page"
					:page-size="queryParam.limit"
					:page-sizes="[2, 20, 30, 50]"
					layout="total, sizes, prev, pager, next, jumper"
					total="100"
				></el-pagination>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { getBalance, getCommissionInfo } from '@api/user';
export default {
	name: 'UserAccount',
	components: {},
	data() {
		return {
			now_money: 0,
			orderStatusSum: 0,
			recharge: 0,
			billList: [],
			queryParam: {
				page: 1,
				limit: 2
			},
			types: '4'
		};
	},
	mounted: function() {
		this.billingDetails();
		this.getIndex();
	},
	methods: {
		// tab跳转
		tabNav(tab) {
			let that = this;
			that.types = tab.name;
			that.queryParam.page = 1;
			that.queryParam.limit = 2;
			that.billingDetails();
		},
		CurrentChange(e) {
			this.queryParam.page = e;
			this.billingDetails();
		},
		SizeChange(e) {
			this.queryParam.limit = e;
			this.billingDetails();
		},
		billingDetails: function() {
			let that = this;
			let types = parseInt(that.types);
			getCommissionInfo(that.queryParam, types).then(
				res => {
					that.billList = res.data;
				},
				error => {
					that.$dialog.message(error.msg);
				}
			);
		},
		getIndex: function() {
			let that = this;
			getBalance().then(
				res => {
					that.now_money = res.data.now_money;
					that.orderStatusSum = res.data.orderStatusSum;
					that.recharge = res.data.recharge;
				},
				err => {
					that.$dialog.message(err.msg);
				}
			);
		}
	}
};
</script>

<style lang="less" scoped>
.title {
	height: 60px;
	line-height: 60px;
	font-weight: 700;
}
.balance {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-weight: 700;
	.left {
		span {
			padding-left: 12px;
			color: #f65b5b;
			font-size: 40px;
		}
	}
	.right {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.list {
			display: flex;
			flex-direction: column;
			width: 200px;
			text-align: center;
			span {
				font-size: 20px;
			}
			span.doing {
				color: #308de5;
			}
		}
	}
}
.btn-ground {
	padding: 20px 0;
	border-bottom: 1px solid #f8f8f8;
	margin-bottom: 20px;
	button {
		cursor: pointer;
		width: 100px;
		height: 32px;
		line-height: 32px;
		margin-right: 30px;
	}
	button.topUp {
		background: #f60;
		color: #fff;
	}
	button.topUp:hover {
		background: #ff8533;

		color: #fff;
	}
	button.withdraw:hover {
		color: #f60;
		background-color: #fff0e6;
	}
}
</style>
